
.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


body{
  background:black;
  font-family: 'Varela', sans-serif;
}

.rainboem0ment{
  background: linear-gradient(to right, #f00 0%, #f80 14.28%, #dd0 28.56%, #0d0 42.85%, #0dd 57.14%, #00f 71.42%, #e0e 85.71%, #f00 100%) 0 center / 200% auto;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  height: 1200px;
  animation: 2.5s rainboem0ment linear infinite;
}
@keyframes rainboem0ment{
  100% { background-position: 200%; }
}

.rainboeshadoowbruh {
    animation: 5s watafaqlmao linear infinite;
}

@keyframes watafaqlmao{
    0% {
        text-shadow: 0 0 20px #f00;
    } 14.28% {
          text-shadow: 0 0 20px #f80;
      } 28.56% {
            text-shadow: 0 0 20px #dd0;
        } 42.85% {
              text-shadow: 0 0 20px #0d0;
          } 57.14% {
                text-shadow: 0 0 20px #0dd;
            } 71.42% {
                  text-shadow: 0 0 20px #00f;
              } 85.71% {
                    text-shadow: 0 0 20px #e0e;
                } 100% {
                      text-shadow: 0 0 20px #f00;

                  }
}

.rainboeshadoowBOXMOMENT {
    animation: 5s watafaqlmaoOOv2 linear infinite;
}

@keyframes watafaqlmaoOOv2{
    0% {
        box-shadow: 0 0 25px #f00;
    } 14.28% {
          box-shadow: 0 0 25px #f80;
      } 28.56% {
            box-shadow: 0 0 30px #dd0;
        } 42.85% {
              box-shadow: 0 0 35px #0d0;
          } 57.14% {
                box-shadow: 0 0 40px #0dd;
            } 71.42% {
                  box-shadow: 0 0 25px #00f;
              } 85.71% {
                    box-shadow: 0 0 30px #e0e;
                } 100% {
                      box-shadow: 0 0 25px #f00;

                  }
}